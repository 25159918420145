import React, { useState, useEffect } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { useActions } from "../../hooks/useActions";
import AboutCard from "./AboutCard";
import styled from "styled-components";

interface User {
  _id: string;
  name: string;
  role?: 'professor' | 'student' | 'alumni';
  // Add other user properties as needed
}

const AboutCards: React.FC = () => {
  const { getAllUsers } = useActions();
  const { data, loading } = useTypedSelector((state: any) => state.allUsersReducer);
  const [allUsers, setAllUsers] = useState<User[]>([]);

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    if (!loading && data.length !== 0) {
      setAllUsers(data);
    }
  }, [data, loading]);

  const filterAndSortUsersByRole = (role: 'professor' | 'student' | 'alumni') => {
    return allUsers
      .filter(user => (user.role || 'student') === role)
      .sort((a, b) => a.name.localeCompare(b.name));
  };

  const renderUserCards = (users: User[]) => (
    <div className="row d-flex m-0 justify-content-center justify-content-md-start">
      {users.map((user) => (
        <div className="col-md-6 col-lg-4 col-xl-3 col-10 my-4 mt-md-0" key={user._id}>
          <AboutCard {...user} />
        </div>
      ))}
    </div>
  );

  return (
    <AboutCardContainer>
      <ScrollableContent>
        <ContentWrapper>
          <Section>
            <SectionTitle>Faculty</SectionTitle>
            <AboutCardList>
              {renderUserCards(filterAndSortUsersByRole('professor'))}
            </AboutCardList>
          </Section>

          <Section>
            <SectionTitle>Students</SectionTitle>
            <AboutCardList>
              {renderUserCards(filterAndSortUsersByRole('student'))}
            </AboutCardList>
          </Section>

          <Section>
            <SectionTitle>Alumni</SectionTitle>
            <AboutCardList>
              {renderUserCards(filterAndSortUsersByRole('alumni'))}
            </AboutCardList>
          </Section>
        </ContentWrapper>
      </ScrollableContent>
    </AboutCardContainer>
  );
};

export default AboutCards;

const AboutCardContainer = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;
`;

const ScrollableContent = styled.div`
  height: 100%;
  overflow-y: scroll;
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
`;

const ContentWrapper = styled.div`
  padding: 2rem;
  padding-bottom: 30vh; // Add extra padding at the bottom

  @media (max-width: 577px) {
    padding: 1rem;
    padding-bottom: 30vh; // Slightly less padding for mobile, but still enough to provide breathing space
  }
`;

const Section = styled.section`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #333;
  padding-bottom: 0.5rem;
`;

const AboutCardList = styled.div`
  margin-top: 1rem;
`;