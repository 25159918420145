import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { motion } from 'framer-motion';
import { useActions } from "../hooks/useActions";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { useNavigate } from "react-router-dom";

interface SearchBarProps {
  onHandleSearch: (data: boolean) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onHandleSearch }) => {
  const [searchOpen, setSearchOpen] = useState<boolean>(true);
  const [search, setSearch] = useState<boolean>(false);
  const [inputVal, setInputVal] = useState<string>('');
  
  const { searchWebpage } = useActions();
  const { data, loading } = useTypedSelector((state) => state.searchReducer);
  const navigate = useNavigate();
  const searchBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data.length !== 0) { 
      navigate('/search', { state: data });
      onHandleSearch(searchOpen);
    } else if (search) {
      navigate('/search', { state: data });
    }
    return () => setSearchOpen(!searchOpen);
  }, [loading, data, searchOpen, search]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target as Node)) {
        onHandleSearch(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onHandleSearch]);

  const onHandleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setSearch(true);
      searchWebpage(e.currentTarget.value);
    }
  }

  return (
    <SearchBarContainer
      ref={searchBarRef}
      initial={{ y: -250 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.1 }}
    >
      <SearchBarContent>
        <CloseIcon
          onClick={() => onHandleSearch(false)}
          className="fa fa-times"
          aria-hidden="true"
        />
        <SearchInput
          onKeyPress={onHandleKeyPress}
          autoFocus
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          placeholder="Search for people, news, workshops, etc."
          type="text"
          value={inputVal}
          onChange={(e) => setInputVal(e.target.value)}
        />
      </SearchBarContent>
    </SearchBarContainer>
  );
};

export default SearchBar;

const SearchBarContainer = styled(motion.div)`
  background-color: #000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: auto;
  min-height: 10rem;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

const SearchBarContent = styled.div`
  width: 100%;
  max-width: 800px;
  position: relative;
`;

const CloseIcon = styled.i`
  color: #fff;
  cursor: pointer;
  position: absolute;
  top: -2rem;
  right: 0;
  font-size: 1.5rem;
`;

const SearchInput = styled(motion.input)`
  outline: 0;
  border-width: 0 0 2px;
  border-color: #fff;
  background: transparent;
  padding: 0.5rem 0;
  width: 100%;
  font-size: 1.5rem;
  color: #fff;
  
  &::placeholder {
    color: gray;
    opacity: 1;
  }

  @media (min-width: 768px) {
    font-size: 2rem;
  }
`;