import React from 'react';
import { useTypedSelector } from './hooks/useTypedSelector';
import { useActions } from './hooks/useActions';
import { useSockets } from './context/socket.context';
import SocketsProvider from './context/socket.context';
import AppRoutes from './routes/AppRoutes';
import Navbar from './components/NavBar';
import Sidebar from './components/Sidebar';
import ContactWidget from './components/contact/ContactWidget';
import SearchBar from './components/SearchBar';
import Footer from './components/Footer';
import './App.scss';

const App = () => {
  const [sidebar, setSidebar] = React.useState(false);
  const [hideLogo, setHideLogo] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const { currentUserData, searchNews } = useActions();
  const { data } = useTypedSelector((state) => state.userReducer);
  const { socket } = useSockets();

  React.useEffect(() => {
    socket.on('newsLoaded', (socket) => {
      searchNews();
    });
  }, []);

  React.useEffect(() => {
    currentUserData();
  }, []);

  React.useEffect(() => {
    socket.on('newsDeleted', (socket) => {
      currentUserData();
    });
  }, []);

  React.useEffect(() => {
    socket.on('projectDeleted', (socket) => {
      currentUserData();
    });
  }, []);

  React.useEffect(() => {
    socket.on('publicationDeleted', (socket) => {
      currentUserData();
    });
  }, []);
  // Define more socket event listeners as needed

  const toggleSidebar = () => {
    setSidebar((prev) => !prev);
    setHideLogo((prev) => !prev);
  };

  return (
    <SocketsProvider>
      <div className="app-wrapper">
        {search && <SearchBar onHandleSearch={setSearch} />}
        <div className="app-content">
          <div
            className='container h-100 d-flex flex-column'
            onClick={() => setSidebar(false)}
          >
            {!data._id && <ContactWidget />}
            <div className='justify-content-between justify-content-lg-start align-items-center d-flex d-lg-block'>
              <span onClick={(e) => e.stopPropagation()}>
                {sidebar ? (
                  <Sidebar
                    headerData={HEADER_DATA}
                    onSelectedChangeLogo={setHideLogo}
                    onSelectedChange={setSidebar}
                  />
                ) : (
                  ''
                )}
                <i
                  onClick={() => {
                    setSidebar(!sidebar);
                    setHideLogo(!hideLogo);
                  }}
                  style={{
                    color: 'var(--primary)',
                    cursor: 'pointer',
                    fontSize: '1.25rem',
                    marginTop: '2rem',
                  }}
                  className='fas fa-bars d-lg-none'
                ></i>
              </span>
              <Navbar
                headerData={HEADER_DATA}
                onHandleSearch={setSearch}
                userData={data}
              />
              <button
                className={`${hideLogo ? 'd-flex' : 'd-none'}`}
                style={{
                  position: 'relative',
                  left: '-4rem',
                  fontSize: '1.25rem',
                }}
                onClick={toggleSidebar}
              >
                <i className='fas fa-bars'></i>
              </button>
            </div>
            <div className="flex-grow-1 overflow-auto">
              <AppRoutes userData={data} />
            </div>
          </div>
        </div>
        {/* <Footer userData={data} /> */}
      </div>
    </SocketsProvider>
  );
};

const HEADER_DATA = [
  { name: 'Home' },
  { name: 'Publications' },
  { name: 'Projects' },
  { name: 'News' },
  { name: 'Team' },
  
];

export default App;