import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

interface NavbarProps {
  headerData: {
    name: string;
  }[];
  userData: {
    role: string;
    email: string;
    name: string;
    _id: string;
    news?: any[];
  };
  onHandleSearch: (data: boolean) => void;
}

const Navbar: React.FC<NavbarProps> = ({ headerData, onHandleSearch }) => {
  const [searchOpen, setSearchOpen] = React.useState<boolean>(false);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState<boolean>(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <NavBar className='nav-bar my-3'>
      <NavbarContent>
        <Logo>
          <HeaderLinks
            to='/'
            end
            className='d-flex p-0'
            style={{ color: '#000' }}
          >
            <img
              style={{ height: '3rem', width: '3rem' }}
              src='https://i.ibb.co/wS9Zn8m/city-logo.jpg'
              alt='logo of city@umd'
            />
            <LogoText>City@UMD</LogoText>
          </HeaderLinks>
        </Logo>
        
        <DesktopMenu>
          {headerData.map((data, index) => (
            <MenuItem key={index}>
              <HeaderLinks
                to={`/${data.name.toLowerCase()}`}
                end
                className={({ isActive }) => (isActive ? 'active' : '')}
              >
                {data.name}
              </HeaderLinks>
            </MenuItem>
          ))}
          <SearchIcon
            onClick={() => {
              setSearchOpen(!searchOpen);
              onHandleSearch(!searchOpen);
            }}
            className='fa fa-search'
            aria-hidden='true'
          />
        </DesktopMenu>

        {/* <MobileMenuToggle onClick={toggleMobileMenu}>
          <i className={`fa ${mobileMenuOpen ? 'fa-times' : 'fa-bars'}`} aria-hidden="true"></i>
        </MobileMenuToggle> */}

        <MobileMenu isOpen={mobileMenuOpen}>
          {headerData.map((data, index) => (
            <MobileMenuItem key={index}>
              <HeaderLinks
                to={`/${data.name.toLowerCase()}`}
                end
                className={({ isActive }) => (isActive ? 'active' : '')}
                onClick={() => setMobileMenuOpen(false)}
              >
                {data.name}
              </HeaderLinks>
            </MobileMenuItem>
          ))}
          <MobileMenuItem>
            <SearchIcon
              onClick={() => {
                setSearchOpen(!searchOpen);
                onHandleSearch(!searchOpen);
                setMobileMenuOpen(false);
              }}
              className='fa fa-search'
              aria-hidden='true'
            />
          </MobileMenuItem>
        </MobileMenu>
      </NavbarContent>
    </NavBar>
  );
};

export default Navbar;

const NavBar = styled.nav`
  width: 100%;
  // padding: 1rem;
`;

const NavbarContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    display: none;
  }
`;

const LogoText = styled.p`
  margin-left: 1rem;
  font-size: 1.75rem;
  font-family: 'Nanum Brush Script', cursive;
  font-weight: 700;
`;

const DesktopMenu = styled.div`
  display: none;

  @media (min-width: 992px) {
    display: flex;
    align-items: center;
  }
`;

const MenuItem = styled.div`
  margin: 0 0.5rem;
`;

const HeaderLinks = styled(NavLink)`
  letter-spacing: 0;
  font-weight: 400;
  color: #525c65;
  background-color: transparent;
  border: 0.125rem solid transparent;
  border-radius: 0;
  transition: opacity 0.3s ease;
  padding: 0 0.5rem;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: #ca4557;
  }

  &.active {
    font-weight: 700;
  }
`;

const SearchIcon = styled.i`
  color: #525c65;
  cursor: pointer;
  margin-left: 1rem;

  &:hover {
    color: #ca4557;
  }
`;

const MobileMenuToggle = styled.div`
  display: block;
  cursor: pointer;
  font-size: 1.5rem;

  @media (min-width: 992px) {
    display: none;
  }
`;

const MobileMenu = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #f9f9f9;
  padding: 1rem;
  z-index: 1000;

  @media (min-width: 992px) {
    display: none;
  }
`;

const MobileMenuItem = styled.div`
  margin: 0.5rem 0;
`;