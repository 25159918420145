import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface IndvUserData {
  email: string;
  name: string;
  _id: string;
  about: string;
  profileImage: string;
  skills: { title: string; highlight: boolean }[];
  expertise: { title: string; highlight: boolean }[];
  hobbies: { title: string; highlight: boolean }[];
  languages: { title: string; highlight: boolean }[];
  to?: string;
  linkedin?: string;
}

const AboutCard: React.FC<any> = ({ ...data }) => {
  const [imageError, setImageError] = useState(false);

  const isExternalImage = (url: string) => {
    return url.startsWith('http://') || url.startsWith('https://');
  };

  const handleImageError = () => {
    console.error(`Failed to load image: ${data.profileImage}`);
    setImageError(true);
  };

  const getImageSrc = () => {
    if (isExternalImage(data.profileImage)) {
      return data.profileImage;
    } else {
      // Adjust this path to match your project structure
      return `/images/${data.profileImage}`;
    }
  };

  return (
    <div className='landing-page-card mb-2 mt-md-0'>
      {data && (
        <Link to={data.to ? `${data.to}/${data._id}` : `${data._id}`}>
          <Card style={{ borderTop: '6px solid var(--primary)' }}>
            <article>
              <div className='row m-0'>
                {!imageError ? (
                  <AboutImage
                    className='p-0'
                    src={getImageSrc()}
                    alt={`Profile of ${data.name}`}
                    onError={handleImageError}
                  />
                ) : (
                  <FallbackImage>{data.name.charAt(0)}</FallbackImage>
                )}
                <h3
                  className='text-center '
                  style={{
                    fontWeight: 300,
                    fontSize: '1.75rem',
                    lineHeight: '2.25rem',
                    color: '#2e3d49',
                  }}
                >
                  {data.name}
                </h3>
                <p
                  className='text-center'
                  style={{
                    height: '10rem',
                    fontStyle: 'italic',
                    color: '#525c65',
                    fontWeight: 400,
                    padding: '0 1.25rem 1.25rem',
                    fontSize: '0.875rem',
                    lineHeight: '1.5rem',
                  }}
                >
                  {data.about && (
                    data.about.length > 200
                      ? `${data.about.substring(0, 200)}...`
                      : data.about
                  )}
                </p>
              </div>
            </article>
          </Card>
        </Link>
      )}
      {data && data['linkedin'] && (
        <div style={{ position: 'relative', top: -40 }}>
          <AboutLines className='p-0 mb-1'>Contact</AboutLines>
          <div className='d-flex justify-content-center'>
            <a
              href={`https://linkedin.com/in/${data['linkedin']}`}
              target='_blank'
              rel="noopener noreferrer"
            >
              <i className='fab fa-linkedin-in mx-1'></i>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default AboutCard;

const Card = styled.div`
  background-color: #ffffff;
  box-shadow: var(--shadow);
  border-radius: 0.375rem;
  height: 21rem;
`;

const AboutImage = styled.img`
  border-radius: 50%;
  box-shadow: var(--shadow);
  height: 4rem;
  width: 4rem;
  margin: 0 auto;
  object-fit: cover;
  transform: translateY(-50%);
  max-width: 100%;
  object-position: center top;
`;

const FallbackImage = styled.div`
  border-radius: 50%;
  box-shadow: var(--shadow);
  height: 4rem;
  width: 4rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  color: white;
  font-size: 2rem;
  transform: translateY(-50%);
`;

const AboutLines = styled.div`
  color: #bcc9d4;
  overflow: hidden;
  text-align: center;
  margin-top: -1rem;
  font-weight: 300;

  &::before,
  &::after {
    background-color: #dbe2e8;
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 5rem;
  }

  &::before {
    left: -0.5rem;
  }

  &::after {
    right: -0.5rem;
  }
`;